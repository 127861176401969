var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('page-section', {
    staticClass: "page-section--first page-section--last"
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "mb-18px mb-md-24px"
  }, [_c('v-icon', {
    attrs: {
      "e": "",
      "size": "60px",
      "color": "primary"
    }
  }, [_vm._v("mdi-alert")])], 1), _c('div', {
    staticClass: "tit"
  }, [_vm._v("페이지를 찾을 수 없습니다")]), _c('div', {
    staticClass: "txt txt--sm mt-12px white-space-pre-line"
  }, [_vm._v(" [404] 페이지를 찾을 수 없습니다. "), _c('br'), _vm._v(" URL 주소를 확인해주세요. ")]), _c('div', {
    staticClass: "btn-wrap btn-wrap--lg"
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large w-100 min-w-sm-200px",
    attrs: {
      "to": "/"
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("홈 화면으로")])], 1)], 1)], 1)])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }