<template>
    <client-page>
        <page-section class="page-section--first page-section--last">
            <div class="text-center">
                <div class="mb-18px mb-md-24px">
                    <v-icon e size="60px" color="primary">mdi-alert</v-icon>
                </div>

                <div class="tit">페이지를 찾을 수 없습니다</div>
                <div class="txt txt--sm mt-12px white-space-pre-line">
                    [404] 페이지를 찾을 수 없습니다. <br />
                    URL 주소를 확인해주세요.
                </div>

                <div class="btn-wrap btn-wrap--lg">
                    <v-row justify="center" class="row--sm">
                        <v-col cols="6" sm="auto">
                            <v-btn v-bind="{ ...btn_primary }" to="/" class="v-size--xx-large w-100 min-w-sm-200px">홈 화면으로</v-btn>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </page-section>
    </client-page>
</template>

<script>
import { btn_primary } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";

export default {
    components: {
        ClientPage,
        PageSection,
    },
    data: () => ({
        btn_primary,
    }),
    mounted() {
        this.init();
    },
    methods: {
        async init() {},
    },
};
</script>

<style lang="scss" scoped>
.white-space-pre-line {
    white-space: pre-line;
}
</style>
